"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookmarkTypes = exports.UserTypes = exports.Gender = void 0;
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["NEUTRAL"] = "NEUTRAL";
})(Gender = exports.Gender || (exports.Gender = {}));
var UserTypes;
(function (UserTypes) {
    UserTypes["PHYSICIAN"] = "physician";
    UserTypes["STAFF"] = "staff";
    UserTypes["PATIENT"] = "patient";
})(UserTypes = exports.UserTypes || (exports.UserTypes = {}));
var BookmarkTypes;
(function (BookmarkTypes) {
    BookmarkTypes["PRESCRIPTIONS"] = "Prescriptions";
})(BookmarkTypes = exports.BookmarkTypes || (exports.BookmarkTypes = {}));
