"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionTypes = void 0;
var SessionTypes;
(function (SessionTypes) {
    SessionTypes["CALL"] = "call";
    SessionTypes["CHAT"] = "chat";
    SessionTypes["INTERNAL_1_ON_1"] = "internal1on1";
    SessionTypes["MEETING"] = "meeting";
    SessionTypes["PM_CHAT"] = "pmChat";
    SessionTypes["TEAM_CHAT"] = "teamChat";
})(SessionTypes = exports.SessionTypes || (exports.SessionTypes = {}));
