import i18next from 'i18next';
import checkoutDe from './lib/de/checkout.json';
import componentsDe from './lib/de/components.json';
import sessionsDe from './lib/de/sessions.json';
import messengerDe from './lib/de/messenger.json';
import globalTranslatesDe from './lib/de/global.json';
import format from './lib/format';
import postProcessor from './lib/postProcessor';

export default ({ plugins, resources = {}, ...options }) => {
  const i18nWithPlugins = [postProcessor, ...plugins].reduce((_, plugin) => _.use(plugin), i18next);

  return i18nWithPlugins.init({
    lng: 'de',
    resources: {
      ...resources,
      de: {
        checkout: checkoutDe,
        components: componentsDe,
        sessions: sessionsDe,
        messenger: messengerDe,
        global: globalTranslatesDe,
        ...resources.de,
      },
    },
    defaultNS: 'global',
    postProcess: 'htmlToReact',
    initImmediate: false,
    interpolation: {
      format: (value, formatStr /* , lng */) => {
        if (value instanceof Date) return format(value, formatStr);
        return value;
      },
    },
    ...options,
  });
};

export { format };
