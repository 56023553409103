"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberTypes = exports.PhoneNumberTags = void 0;
var PhoneNumberTags;
(function (PhoneNumberTags) {
    PhoneNumberTags["MOBILE"] = "MOBILE";
    PhoneNumberTags["LANDLINE"] = "FIXED_LINE";
    PhoneNumberTags["OTHER"] = "OTHER";
})(PhoneNumberTags = exports.PhoneNumberTags || (exports.PhoneNumberTags = {}));
var PhoneNumberTypes;
(function (PhoneNumberTypes) {
    PhoneNumberTypes[PhoneNumberTypes["LANDLINE"] = 0] = "LANDLINE";
    PhoneNumberTypes[PhoneNumberTypes["MOBILE"] = 1] = "MOBILE";
})(PhoneNumberTypes = exports.PhoneNumberTypes || (exports.PhoneNumberTypes = {}));
