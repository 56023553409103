"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaConfigStatus = exports.TaStepTypes = exports.TaCallStatus = void 0;
var TaCallStatus;
(function (TaCallStatus) {
    TaCallStatus["COMPLETED"] = "COMPLETED";
    TaCallStatus["UNCOMPLETED"] = "UNCOMPLETED";
    TaCallStatus["CANCELED"] = "CANCELED";
    TaCallStatus["IN_PROGRESS"] = "IN_PROGRESS";
    TaCallStatus["BUSY"] = "BUSY";
    TaCallStatus["NO_ANSWER"] = "NO_ANSWER";
    TaCallStatus["FAILED"] = "FAILED";
    TaCallStatus["RINGING"] = "RINGING";
})(TaCallStatus = exports.TaCallStatus || (exports.TaCallStatus = {}));
var TaStepTypes;
(function (TaStepTypes) {
    TaStepTypes["GENERIC"] = "GENERIC";
    TaStepTypes["LANGUAGE"] = "LANGUAGE";
    TaStepTypes["IS_MOBILE"] = "IS_MOBILE";
    TaStepTypes["KNOWN_USER"] = "KNOWN_USER";
    TaStepTypes["GATHER"] = "GATHER";
    TaStepTypes["RECORD"] = "RECORD";
    TaStepTypes["TIME"] = "TIME";
    TaStepTypes["DIAL"] = "DIAL";
})(TaStepTypes = exports.TaStepTypes || (exports.TaStepTypes = {}));
var TaConfigStatus;
(function (TaConfigStatus) {
    TaConfigStatus["ACTIVE"] = "ACTIVE";
    TaConfigStatus["INACTIVE"] = "INACTIVE";
})(TaConfigStatus = exports.TaConfigStatus || (exports.TaConfigStatus = {}));
