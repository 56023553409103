"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffsetFromPage = exports.getPageFromOffset = void 0;
var getPageFromOffset = function (_a) {
    var offset = _a.offset, limit = _a.limit;
    return offset / limit + 1;
};
exports.getPageFromOffset = getPageFromOffset;
var getOffsetFromPage = function (page, _a) {
    var limit = _a.limit;
    return (page - 1) * limit;
};
exports.getOffsetFromPage = getOffsetFromPage;
