"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageTypes = void 0;
var MessageTypes;
(function (MessageTypes) {
    MessageTypes["APPOINTMENT"] = "appointment";
    MessageTypes["FINDINGS"] = "findings";
    MessageTypes["MULTICAST"] = "multicast";
    MessageTypes["PRESCRIPTION"] = "prescription";
    MessageTypes["WELCOME"] = "welcome";
    MessageTypes["PATIENT"] = "patient";
    MessageTypes["SYSTEM"] = "system";
    MessageTypes["DEMO"] = "demo";
    MessageTypes["PAM"] = "pam";
})(MessageTypes = exports.MessageTypes || (exports.MessageTypes = {}));
