"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidDateOfBirth = exports.maxAge = void 0;
exports.maxAge = 120;
var isValidDateOfBirth = function (dateOfBirth) {
    if (!(dateOfBirth instanceof Date) || Number.isNaN(dateOfBirth)) {
        return false;
    }
    var currentDate = new Date();
    var minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - exports.maxAge);
    return dateOfBirth >= minDate && dateOfBirth < currentDate;
};
exports.isValidDateOfBirth = isValidDateOfBirth;
