"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobilePhoneNumber = exports.isValidPhoneNumber = exports.phoneNumberTypeToTag = void 0;
var shared_types_1 = require("@medflex/shared-types");
var commons_1 = require("@medflex/commons");
var phoneNumberTypeToTag = function (type) {
    if (type === shared_types_1.PhoneNumberTypes.LANDLINE) {
        return shared_types_1.PhoneNumberTags.LANDLINE;
    }
    if (type === shared_types_1.PhoneNumberTypes.MOBILE) {
        return shared_types_1.PhoneNumberTags.MOBILE;
    }
    return shared_types_1.PhoneNumberTags.OTHER;
};
exports.phoneNumberTypeToTag = phoneNumberTypeToTag;
var isValidPhoneNumber = function (number) { return commons_1.phoneNumber.transformableToE164(number); };
exports.isValidPhoneNumber = isValidPhoneNumber;
var isMobilePhoneNumber = function (number) {
    if (!commons_1.phoneNumber.transformableToE164(number)) {
        return false;
    }
    var numberType = commons_1.phoneNumber.getNumberType(number);
    return shared_types_1.PhoneNumberTypes.MOBILE === numberType;
};
exports.isMobilePhoneNumber = isMobilePhoneNumber;
