"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvitationMessage = exports.getIFunnelInvitationMessage = void 0;
var users_utils_1 = require("./users.utils");
var getIFunnelInvitationMessage = function (inviter, invitationLink) {
    return [
        (0, users_utils_1.getWorkplaceOrTFL)(inviter),
        "ist f\u00FCr Sie einfach digital erreichbar. Verbinden Sie sich jetzt kostenlos, um Ihre Anliegen schnell & sicher zu \u00FCbermitteln: ".concat(invitationLink),
    ].join(' ');
};
exports.getIFunnelInvitationMessage = getIFunnelInvitationMessage;
var getInvitationMessage = function (inviter, invitationLink) {
    return [
        'Sie haben bei',
        (0, users_utils_1.getWorkplaceOrTFL)(inviter),
        "angerufen. Registrieren Sie sich hier, damit Ihre Praxis Sie erreichen kann: ".concat(invitationLink),
    ].join(' ');
};
exports.getInvitationMessage = getInvitationMessage;
