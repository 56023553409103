"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextSnippetTags = void 0;
var TextSnippetTags;
(function (TextSnippetTags) {
    TextSnippetTags["PAM_REQUEST_QUICK_REPLY"] = "pamRequestQuickReply";
    TextSnippetTags["APPOINTMENT"] = "appointment";
    TextSnippetTags["GENERAL_INQUIRY"] = "general_inquiry";
    TextSnippetTags["FOLLOW_UP_PRESCRIPTION"] = "request_follow_up_prescription";
    TextSnippetTags["REFERRAL"] = "referral";
    TextSnippetTags["FINDINGS_INQUIRY"] = "findings_inquiry";
    TextSnippetTags["PHONE"] = "phone";
})(TextSnippetTags = exports.TextSnippetTags || (exports.TextSnippetTags = {}));
